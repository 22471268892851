import { IComponentController } from '@wix/editor-elements-types';

const mapActionsToProps: IComponentController = ({ updateProps }) => {
  return {
    onTokenChange: (token?: string) => {
      updateProps({
        token,
      });
    },
  };
};

export default mapActionsToProps;
